import { Container } from "react-bootstrap";

export const Footer = () => {
    return (
        <section id="footer" className="footer">
            <Container>
                <p>
                    © {new Date().getFullYear()} Seven Ducks Studios
                </p>
            </Container>
        </section>
    );
}